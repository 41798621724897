import { Col, FloatButton, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { urnFetchShoppingProductById } from "../../../../api/urn";
import { useFetchParams } from "../../../../hook/useFetchParams";
import ProductCategoryItem from "./ProductCategoryItem";
import { useMediaQuery } from "react-responsive";

export default function ProductCategoryList() {
  const params = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { fetchParamResponse, fetchParamError, fetchDataParams } =
    useFetchParams(urnFetchShoppingProductById(params.categoryId));
  const xl = useMediaQuery({
    query: "(min-width: 1200px)",
  });
  const lg = useMediaQuery({ query: "(min-width: 992px)" });
  const md = useMediaQuery({ query: "(min-width: 768px)" });
  const sm = useMediaQuery({ query: "(min-width: 576px)" });
  const xs = useMediaQuery({ query: "(max-width: 576px)" });
  useEffect(() => {
    if (params?.categoryId) {
      fetchDataParams();
    }
  }, [params]);

  useEffect(() => {
    if (fetchParamResponse) {
      setData(fetchParamResponse);
      setLoading(false);
    }
  }, [fetchParamResponse]);

  const styles = {
    row: {
      padding: xs ? "0 10px" : "0 50px",
    },
    title: {
      color: "rgb(239 215 30)",
      borderBottom: "1px solid gold",
      margin: "20px 0",
      fontSize: xs && "16px",
    },
    search: {
      marginTop: "20px",
      // marginBottom: '0 !important',
      // color: 'white !important',
    },
  };
  if (xs) {
    return (
      <Row justify="start" gutter={[0, 10]} style={styles.row}>
        {data[0]?.categoryName && (
          <Col span={24}>
            <Typography.Title level={4} style={styles.title}>
              {data[0]?.categoryName}
            </Typography.Title>
          </Col>
        )}

        <Col span={24}>
          {data?.length > 0 ? (
            <Row gutter={[10, 10]}>
              {data?.map((item) => {
                return (
                  <Col span={12}>
                    <ProductCategoryItem item={item} />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <Typography.Title level={4} style={{ color: "#fff" }}>
              Không có sản phẩm cho mục này
            </Typography.Title>
          )}
        </Col>
      </Row>
    );
  }

  return (
    <>
      {/* <FloatButton.BackTop visibilityHeight={300} /> */}

      <Row justify="start" gutter={[0, 10]} style={styles.row}>
        {data[0]?.categoryName && (
          <Col span={24}>
            <Typography.Title level={4} style={styles.title}>
              {data[0]?.categoryName}
            </Typography.Title>
          </Col>
        )}

        <Col span={24}>
          {data?.length > 0 ? (
            <Row gutter={[0, 30]}>
              {data?.map((item) => {
                return (
                  <Col span={24}>
                    <ProductCategoryItem item={item} />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <Typography.Title level={4} style={{ color: "#fff" }}>
              Không có sản phẩm cho mục này
            </Typography.Title>
          )}
        </Col>
      </Row>
    </>
  );
}
