import { Col, Row } from "antd";
import React from "react";
import InsertCategoryForm from "../../../../modules/Form/InsertCategoryForm";
import Heading from "../../../../modules/Heading/Heading";
import { useLocation } from "react-router-dom";

export default function InsertCategoryPage() {
  const { state } = useLocation();
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Heading title="Insert new category" />
      </Col>
      <Col span={6}>
        <InsertCategoryForm item={state} />
      </Col>
    </Row>
  );
}
