import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "antd";
import Heading from "../../../../modules/Heading/Heading";
import InsertImageForm from "../../../../modules/Form/InsertImageForm";

export default function InsertImagePage() {
  const { productId } = useParams();

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Heading title="Insert images" />
      </Col>
      <Col span={6}>
        <InsertImageForm productId={productId} />
      </Col>
    </Row>
  );
}
