import { Col, Row } from "antd";
import React from "react";
import ProductCarousel from "../../../../modules/Carousel/ProductCarousel";
import BrandImage from "../../../../modules/Image/BrandImage";
import { useMediaQuery } from "react-responsive";

export default function BrandItem(props) {
  const xs = useMediaQuery({ query: "(max-width: 576px)" });

  if (xs) {
    return (
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <BrandImage index={props?.index} />
        </Col>
        <Col span={24}>
          <ProductCarousel id={props?.item?.id} />
        </Col>
      </Row>
    );
  }
  return (
    <>
      {props?.index % 2 === 0 ? (
        <Row gutter={[40, 10]}>
          <Col flex="420px">
            <BrandImage index={props?.index} />
          </Col>
          <Col flex="400px">
            <ProductCarousel id={props?.item?.id} />
          </Col>
        </Row>
      ) : (
        <Row gutter={[40]}>
          <Col flex="400px">
            <ProductCarousel id={props?.item?.id} />
          </Col>
          <Col flex="420px">
            <BrandImage index={props?.index} />
          </Col>
        </Row>
      )}
    </>
  );
}
