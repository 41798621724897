import { notification } from "antd";
import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

export const AuthContext = createContext();
export const MessageContext = createContext();

export default function AuthProvider({ children }) {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const token = localStorage.getItem("access_token");
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const openNotification = (message, type) => {
    api.info({
      message: capitalizeFirstLetter(type),
      description: message,
      placement: "bottomRight",
      icon: getIcon(type),
      duration: 4,
    });
  };
  const getIcon = (type) => {
    switch (type) {
      case "success":
        return <CheckCircleOutlined style={{ color: "green" }} />;
      case "warning":
        return <ExclamationCircleOutlined style={{ color: "gold" }} />;
      case "error":
        return <CloseCircleOutlined style={{ color: "red" }} />;
      default:
        return;
    }
  };

  useEffect(() => {
    if (token) {
      navigate("/admin/dashboard");
    } else {
      navigate("/admin/login");
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <MessageContext.Provider value={{ openNotification }}>
        {contextHolder}
        {children}
      </MessageContext.Provider>
      {/* {children} */}
    </AuthContext.Provider>
  );
}
