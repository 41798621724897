import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import "./Editor.css";

function EditorClassic(props) {
  const config = {
    placeholder: "Type or paste your content here!",
    plugins: [
      "Alignment",
      "AutoLink",
      "Autosave",
      "BlockQuote",
      "Bold",
      "FindAndReplace",
      "FontBackgroundColor",
      "FontColor",
      "FontFamily",
      "FontSize",
      "Highlight",
      "Indent",
      "IndentBlock",
      "Italic",
      "Link",
      "List",
      "ListProperties",
      "Paragraph",
      "SpecialCharacters",
      "SpecialCharactersArrows",
      "SpecialCharactersCurrency",
      // 'SpecialCharactersEssentials',
      "SpecialCharactersLatin",
      "SpecialCharactersMathematical",
      "SpecialCharactersText",
      "Strikethrough",
      "Subscript",
      "Superscript",
      "Underline",
    ],
    toolbar: {
      items: [
        "bold",
        "italic",
        "|",
        "fontBackgroundColor",
        "fontColor",
        "fontFamily",
        "fontSize",
        "highlight",
        "|",
        "outdent",
        "indent",
        "bulletedList",
        "numberedList",
        "alignment",
        "|",
        "link",
        "findAndReplace",
        "specialCharacters",
        "strikethrough",
        "subscript",
        "superscript",
        "underline",
        "|",
        "undo",
        "redo",
      ],
      shouldNotGroupWhenFull: true,
    },
    language: "en",
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    fontFamily: {
      supportAllValues: true,
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    // removePlugins: "bold",
  };

  return (
    <>
      <CKEditor
        config={config}
        data={props?.data || ""}
        contenteditable={false}
        editor={Editor}
        onReady={(editor) => {
          //   setEditor(editor);
        }}
        onChange={(editor, data) => {
          //   console.log(
          //     "🚀 ~ file: Editor.jsx:104 ~ EditorClassic ~ data:",
          //     data.getData()
          //   );
          props?.onChange(data.getData());
        }}
      />
    </>
  );
}

export default EditorClassic;
