import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import "./ShoppingSiderMenu.css";
import {
  FileImageOutlined,
  FileTextOutlined,
  InboxOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../hook/useFetch";
import { urnFetchCategory } from "../../api/urn";
import { useRecursion } from "../../hook/useRecursion";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

// const items = [getItem('Category', '/admin/dashboard/category', <InboxOutlined />), getItem('Product', '/admin/dashboard/products', <StarOutlined />), getItem('Image', '/admin/dashboard/image', <FileImageOutlined />), getItem('Post', '/admin/dashboard/post', <FileTextOutlined />)];

export default function ShoppingSiderMenu() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState([]);
  const { response, error } = useFetch(urnFetchCategory);
  const { result, onRecursion } = useRecursion(category, "menu");
  const { t } = useTranslation();

  const xl = useMediaQuery({
    query: "(min-width: 1200px)",
  });
  const lg = useMediaQuery({ query: "(min-width: 992px)" });
  const md = useMediaQuery({ query: "(min-width: 768px)" });
  const sm = useMediaQuery({ query: "(min-width: 576px)" });
  const xs = useMediaQuery({ query: "(max-width: 576px)" });

  const styles = {
    menu: {
      backgroundColor: "rgb(49 49 49 / 95%)",
      color: "rgb(239 215 30)",
      maxHeight: "70vh",
      overflow: "auto",
      fontSize: xs && "13px",
    },
  };
  const handleClick = (e) => {
    navigate("/products/" + e.key);
  };
  const getGroupMenu = () => {
    if (xl) {
      return [
        {
          label: "Category",
          key: "categoryGroup",
          icon: null,
          children: result,
          type: "group",
        },
      ];
    } else {
      return [
        {
          label: "Category",
          key: "categoryGroup",
          icon: null,
          children: result,
          type: "group",
        },
        {
          label: "Infomation",
          key: "infoGroup",
          icon: null,
          children: getPosts(),
          type: "group",
        },
      ];
    }
  };

  const getPosts = () => {
    return [
      {
        label: t("navbar.aboutUs"),
        key: "aboutUs",
        icon: null,
        children: null,
      },
      {
        label: t("navbar.howToBuy"),
        key: "howToBuy",
        icon: null,
        children: null,
      },
    ];
  };

  useEffect(() => {
    if (response) {
      setCategory(response);
    }
    setLoading(false);
  }, [response]);

  useEffect(() => {
    if (category) {
      onRecursion();
    }
  }, [category]);
  return (
    <Menu
      style={styles.menu}
      mode="inline"
      items={result && getGroupMenu()}
      onClick={handleClick}
      className="shopping-sider-menu"
    />
  );
}
