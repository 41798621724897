import { Button, Form, Image, Radio, Table, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { path, url } from "../../api/url";
import { urnDeleteImages, urnGetImages, urnUpdateAvatar } from "../../api/urn";
import { MessageContext } from "../../context/AuthProvider";
import { useFetchParams } from "../../hook/useFetchParams";

export default function ImageTable(props) {
  // props :
  // categoryId => get data
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { openNotification } = useContext(MessageContext);
  const { fetchParamResponse, fetchParamError, fetchDataParams } =
    useFetchParams(urnGetImages(props?.productId));

  useEffect(() => {
    if (props.productId) {
      fetchDataParams();
    }
  }, [props.productId]);

  useEffect(() => {
    if (fetchParamResponse) {
      setData(fetchParamResponse);
    }
    setLoading(false);
  }, [fetchParamResponse]);

  const onChangeAvatar = (value, record) => {
    if (record?.size === "large") {
      openNotification("Chỉ được đặt ảnh size nhỏ làm đại diện!", "warning");
    } else {
      setLoading(true);
      let currentAvatar = data?.find((x) => Boolean(x.isAvatar));
      console.log(currentAvatar);
      axios
        .put(url + urnUpdateAvatar, [
          { id: record?.id, isAvatar: value },
          { id: currentAvatar?.id, isAvatar: false },
        ])
        .then((response) => {
          setTimeout(() => {
            updateStateAvatar(value, record?.id, currentAvatar?.id);
            openNotification("Successfully updated", "success");
            setLoading(false);
          }, 200);
        });
    }
  };

  const updateStateAvatar = (value, id, oldId) => {
    setData(
      data.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            isAvatar: value,
          };
        } else if (item.id === oldId) {
          return {
            ...item,
            isAvatar: false,
          };
        } else {
          return item;
        }
      })
    );
  };

  const onDeleteImage = (item) => {
    if (Boolean(item.isAvatar)) {
      openNotification("Không thể xóa ảnh đại diện", "warning");
    } else {
      axios
        .delete(url + urnDeleteImages, {
          data: { id: item?.id, name: item?.name },
        })
        .then((response) => {
          openNotification("Successfully deleted", "success");
          setData(data?.filter((x) => x.id !== item.id));
        })
        .catch((err) => {
          console.log(
            "🚀 ~ file: ImageTable.jsx:85 ~ onDeleteImage ~ err:",
            err
          );
          openNotification(err, "error");
        });
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "5%",
    },

    {
      title: "Avatar",
      dataIndex: "isAvatar",
      width: "5%",
      render: (value, record) => {
        console.log(record);
        return (
          <Radio
            checked={Boolean(record?.isAvatar)}
            onChange={(e) => onChangeAvatar(e.target.checked, record)}
          ></Radio>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Image",
      dataIndex: "",
      align: "center",
      render: (record) => {
        return <Image width={100} src={path + record.name} />;
      },
    },
    {
      title: "Image size",
      dataIndex: "size",
      width: "10%",
    },
    {
      title: "...",
      dataIndex: "",
      width: "10%",
      render: (record) => {
        return (
          <>
            <Tooltip placement="top" title="Delete" arrow={true}>
              <Button
                type="text"
                shape="round"
                danger
                icon={<DeleteOutlined />}
                onClick={() => onDeleteImage(record)}
              ></Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <Form form={form} component={false}>
      <Table
        scroll={{
          y: 500,
        }}
        rowKey={(record) => record.id}
        loading={loading}
        bordered
        dataSource={data}
        columns={columns}
        expandable={{
          rowExpandable: (record) => record.children,
        }}
      />
    </Form>
  );
}
