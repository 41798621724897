import { useState } from "react";
import { url } from "../api/url";
import axios from "axios";

export const useFetchParams = (urn, params, ptions) => {
  const [fetchParamResponse, setFetchParamResponse] = useState(null);
  const [fetchParamError, setFetchParamError] = useState(null);
  const fetchDataParams = async () => {
    await axios
      .get(url + urn)
      .then((response) => setFetchParamResponse(response.data))
      .catch((error) => setFetchParamError(error));
  };
  return { fetchParamResponse, fetchParamError, fetchDataParams };
};
