import { Col, Row } from "antd";
import React from "react";
import Heading from "../../../../modules/Heading/Heading";
import ImageContent from "../../../../components/Admin/Content/Image/ImageContent";
import { useParams } from "react-router-dom";

export default function AdminImagePage() {
  const param = useParams();

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Heading title="Images" />
      </Col>
      <Col span={24}>
        <ImageContent productId={param?.productId} />
      </Col>
    </Row>
  );
}
