import { Button, Form, Select, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import ImageUpload from "../Upload/ImageUpload";
import axios from "axios";
import { url } from "../../api/url";
import { urnAddImages } from "../../api/urn";
import { MessageContext } from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";

export default function InsertImageForm(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [fileList, setFileList] = useState([]);
  const { openNotification } = useContext(MessageContext);
  const options = [
    {
      value: "small",
      label: "Small",
    },
    { value: "large", label: "Large" },
  ];

  const propsFileList = {
    name: "file",
    onChange({ file, fileList }) {
      if (file.status !== "uploading" && file.status !== "removed") {
        setFileList((old) => [...old, file]);
      }
    },
    onRemove: (file) => {
      setFileList(fileList.filter((x) => x.uid !== file.uid));
    },
    beforeUpload: (file) => {
      return false;
    },
    fileList,
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const saveFile = (data) => {
    axios
      .post(
        url +
          urnAddImages +
          data?.size?.charAt(0).toUpperCase() +
          data?.size?.slice(1),
        {
          files: data?.image?.map((item) => {
            return item.originFileObj;
          }),
        },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((response) => {
        openNotification(response.data, "success");
        navigate(-1);
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };

  const onFinish = (value) => {
    if (value?.image) {
      axios
        .post(url + urnAddImages, {
          data: value?.image?.map((item, index) => {
            return {
              name: value.size + "_" + item.name,
              size: value?.size,
              isAvatar: false,
              productId: props.productId,
            };
          }),
        })
        .then((response) => {
          if (response.data) {
            saveFile(value);
          }
        })
        .catch((err) => {
          openNotification(err, "error");
        });
    }
  };
  const onFinishFailed = (value) => {
    console.log(
      "🚀 ~ file: InsertImageForm.jsx:46 ~ onFinishFailed ~ value:",
      value
    );
    openNotification("Failed inserted", "error");
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Size"
        name="size"
        rules={[
          {
            required: true,
            message: "Please select size!",
          },
        ]}
      >
        <Select
          loading={loading}
          placeholder="Select a size"
          name="size"
          // onChange={(value) => handleSelect(value)}
          options={options}
        />
      </Form.Item>

      <Form.Item
        label="Image"
        name="image"
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload.Dragger
          listType="picture"
          {...propsFileList}
          name="files"
          maxCount={5}
          multiple
          // onChange={(e) => console.log("e", e)}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
