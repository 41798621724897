import { BackTop, Col, FloatButton, Layout, Row } from "antd";
import React from "react";
import { Outlet } from "react-router-dom";
import ProductSearch from "../../../modules/Search/ProductSearch";
const { Content } = Layout;

export default function ShoppingContent() {
  const styles = {
    row: {
      padding: "0 50px",
    },
    height: "calc(100vh - 128px)",
  };
  return (
    <Content
      style={{
        background: "black ",
        height: "calc(100vh - 128px)",
        overflowY: "auto",
        // marginBottom: '50px',
      }}
    >
      {/* <Breadcrumb /> */}
      <BackTop visibilityHeight={300} />
      <Outlet />
    </Content>
  );
}
