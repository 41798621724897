import "./index.css";
import React, { useState } from "react";
import { Drawer, Layout, Menu, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import LogoTop from "../../../assets/logo/logo-chrono24.jpg";
import ShoppingSiderMenu from "../../../modules/Menu/ShoppingSiderMenu";
import { useMediaQuery } from "react-responsive";
const { Sider } = Layout;

export default function ShoppingSidebar(props) {
  const navigate = useNavigate();
  const xl = useMediaQuery({
    query: "(min-width: 1200px)",
  });
  const lg = useMediaQuery({ query: "(min-width: 992px)" });
  const md = useMediaQuery({ query: "(min-width: 768px)" });
  const sm = useMediaQuery({ query: "(min-width: 576px)" });
  const xs = useMediaQuery({ query: "(max-width: 576px)" });
  const [collapsed, setCollapsed] = useState(false);

  console.log(props);
  const styles = {
    sider: {
      backgroundColor: "rgb(49 49 49 / 95%)",
      boxShadow: `rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px`,
    },
    menu: {
      backgroundColor: "transparent",
      color: "#fff",
    },
  };

  const backHome = () => {
    navigate("/");
  };
  if (xl) {
    return (
      <Sider
        style={styles.sider}
        width={250}
        // breakpoint="lg"
        // collapsedWidth="0"
        collapsible
        collapsed={collapsed}
        className="shopping-sidebar"
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="logo-top" onClick={() => backHome()}>
          <img src={LogoTop} alt="" />
        </div>
        {/* <Typography.Text strong className='sider-menu-label'>
        Category
      </Typography.Text> */}
        <ShoppingSiderMenu />
      </Sider>
    );
  } else {
    return (
      <Drawer
        style={styles.sider}
        title={
          <span style={{ color: "#fff" }}>
            Chrono<span style={{ color: "#C38D51" }}>24</span>us
          </span>
        }
        placement="left"
        onClose={props?.onClose}
        open={props?.open}
        width={250}
        id="sidebar-drawer"
      >
        <ShoppingSiderMenu />
      </Drawer>
    );
  }
}
