import React from "react";
import { Avatar, Button, Col, Layout, Row, Typography, theme } from "antd";
import { useNavigate } from "react-router-dom";
const { Header } = Layout;
export default function AdminHeader() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Header
      style={{
        padding: "0 20px",
        background: colorBgContainer,
      }}
    >
      <Row justify="end" gutter={[8]}>
        <Col>
          <Avatar>Admin</Avatar>
        </Col>
        <Col>
          <Typography.Text>Admin</Typography.Text>
        </Col>
      </Row>
    </Header>
  );
}
