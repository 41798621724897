import { useState } from "react";
import WristWatches from "../assets/icons/wrist-watch.png";
import WatchAccessories from "../assets/icons/strap.png";
import PocketWatches from "../assets/icons/pocket-watch.png";
import Clock from "../assets/icons/clock.png";
export const useRecursion = (list, type) => {
  const [result, setResult] = useState([]);

  const getIcon = (name) => {
    switch (name) {
      case "Wrist Watches":
        return WristWatches;
      case "Watch Accessories":
        return WatchAccessories;
      case "Pocket Watches":
        return PocketWatches;
      case "Clock":
        return Clock;
      default:
        return;
    }
  };

  const getChild = (parentId) => {
    return getOptions(list.filter((item) => item.parentId === parentId));
  };
  const getOptions = (list) => {
    switch (type) {
      //   case "table":
      //     return list?.map((item) => {
      //       return {
      //         key: item.id,
      //         dataIndex: item.name,
      //         title: item.name,
      //         children: getChild(item.id),
      //       };
      //     });
      case "menu":
        if (list.length === 0) {
          return undefined;
        } else {
          return list?.map((item) => {
            return {
              key: item.id,
              icon: item.hasIcon ? (
                <img
                  src={getIcon(item.name)}
                  alt=""
                  className="icons-sidebar"
                />
              ) : null,
              children: getChild(item.id),
              label: item.name,
              // theme: 'rgb(49 49 49 / 95%)',
            };
          });
        }
      default:
        return list?.map((item) => {
          return {
            value: item.id,
            label: item.name,
            parentId: item.parentId,
            nameEn: item.nameEn,
            children: getChild(item.id),
          };
        });
    }
  };
  const onRecursion = () => {
    setResult(getOptions(list.filter((item) => item.parentId === null)));
  };
  return { result, onRecursion };
};
