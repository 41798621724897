import { useEffect, useState } from "react";
import { url } from "../api/url";
import axios from "axios";

export const usePost = (urn, options) => {
  const [postResponse, setPostResponse] = useState(null);
  const [postError, setPostError] = useState(null);

  const postData = () => {
    axios
      .post(url + urn, options)
      .then((response) => setPostResponse(response))
      .catch((error) => setPostError(error));
  };
  return { postResponse, postError, postData };
};
