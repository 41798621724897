import React, { useState } from "react";
import { Button, Col, Row, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ProductTable from "../../../../modules/Table/ProductTable";
import CategoryTreeSelect from "../../../../modules/TreeSelect/CategoryTreeSelect";

export default function ProductContent() {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState();
  const handleInsertClick = () => {
    navigate("/admin/dashboard/product/insert");
  };
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row justify="start" gutter={[16]}>
          <Col>
            <Typography.Text>Category: </Typography.Text>
            <CategoryTreeSelect onSelect={setSelectedCategory} />
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleInsertClick}
            >
              Add Product
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>{<ProductTable categoryId={selectedCategory} />}</Col>
    </Row>
  );
}
