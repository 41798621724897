import { Drawer, Layout, Typography, theme } from "antd";
import Header from "../../../components/Shopping/Header/index";
import Sidebar from "../../../components/Shopping/Sidebar/index";
import ShoppingContent from "../../../components/Shopping/Content";
import { Context as ResponsiveContext, useMediaQuery } from "react-responsive";
import { useState } from "react";
import ShoppingSiderMenu from "../../../modules/Menu/ShoppingSiderMenu";
// import { Context as ResponsiveContext } from 'react-responsive'
const { Footer } = Layout;

export default function ShoppingDashBoard() {
  const xl = useMediaQuery({
    query: "(min-width: 1200px)",
  });
  const lg = useMediaQuery({ query: "(min-width: 992px)" });
  const md = useMediaQuery({ query: "(min-width:: 768px)" });
  const sm = useMediaQuery({ query: "(min-width:: 576px)" });
  const xs = useMediaQuery({ query: "(max-width: 576px)" });
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sidebar onClose={onClose} open={open} />

      <Layout>
        <Header showDrawer={showDrawer} />

        <ShoppingContent />
        <Footer
          style={{
            textAlign: "center",
            background: "black",
            color: "white",
            height: xs ? "32px" : "64px",
            lineHeight: xs ? "32px" : "64px",
            fontSize: xs && "12px",
            paddingTop: "0",
          }}
        >
          Chrono24us ©2023 Created by Shop Minh Quy
        </Footer>
      </Layout>
    </Layout>
  );
}
