import { CaretRightOutlined } from "@ant-design/icons";
import { Col, Row, Skeleton, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { path } from "../../../../api/url";
import { urnFetchProductDetail } from "../../../../api/urn";
import { useFetchParams } from "../../../../hook/useFetchParams";
import PreviewCollection from "../../../../modules/Image/PreviewCollection";
import ProductSubImageList from "./ProductSubImageList";
import "./index.css";
import { useMediaQuery } from "react-responsive";

export default function ProductDetail() {
  const params = useParams();
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [mainImage, setMainImage] = useState(null);
  const [magnify, setMagnify] = useState({});
  const { fetchParamResponse, fetchParamError, fetchDataParams } =
    useFetchParams(urnFetchProductDetail(params?.productId));

  const xl = useMediaQuery({
    query: "(min-width: 1200px)",
  });
  const lg = useMediaQuery({ query: "(min-width: 992px)" });
  const md = useMediaQuery({ query: "(min-width: 768px)" });
  const sm = useMediaQuery({ query: "(min-width: 576px)" });
  const xs = useMediaQuery({ query: "(max-width: 576px)" });
  useEffect(() => {
    if (params?.productId) {
      fetchDataParams();
    }
  }, [params]);

  useEffect(() => {
    if (fetchParamResponse) {
      setData(fetchParamResponse);
      setLoading(false);
    }
  }, [fetchParamResponse]);

  useEffect(() => {
    if (mainImage) {
      setMagnify({
        ...magnify,
        backgroundImage: `url(${path + mainImage.replace("small", "large")})`,
      });
    } else {
      setMagnify({
        ...magnify,
        backgroundImage: `url(${
          path +
          data?.imageSmall
            ?.find((x) => x.isAvatar)
            .name.replace("small", "large")
        })`,
      });
    }
  }, [mainImage, data]);

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setMagnify({
      ...magnify,
      backgroundPosition: `${x}% ${y}%`,
      transform: "scale(1.2)",
    });
  };

  const createMarkup = (value) => {
    if (value !== null && value !== "") {
      return { __html: value };
    }
  };

  const styles = {
    row: {
      padding: xs ? "0 10px" : "0 50px",
    },
    title: {
      color: "rgb(239 215 30)",
      margin: "20px 0",
      borderBottom: "1px solid gold",
      fontSize: xs && "16px",
    },
    name: {
      color: "rgb(239 215 30)",
      fontSize: xs && "12px",
    },
    key: {
      color: "#fff",
    },
    price: {
      color: "red",
      width: "100%",
      fontWeight: "bold",
    },
    divider: {
      background: "#fff",
      margin: "14px 0",
    },
    description: {
      color: "#fff",
      marginBottom: "0",
      textAlign: "justify",
    },
    buttonWrapper: {
      width: "100%",
    },
    buttonDetail: {
      background: "transparent",
      color: "rgb(239 215 30)",
      borderColor: "gold",
      width: "200px",
      marginTop: "10px",
    },
    imageSmallContainer: {
      width: "200px",
      height: "200px",
    },
    imageLargeContainer: {
      width: "750px",
      height: "750px",
    },
    video: {
      marginTop: "20px",
    },
    bullet: {
      color: "white",
      fontSize: "10px",
    },
    iframe: {
      width: xs ? "100%" : "600px",
      height: xs ? "240px" : "340px",
    },
  };

  if (xs) {
    return (
      <Row justify="start" gutter={[0, 10]} style={styles.row}>
        <Col span={24}>
          <Typography.Title level={4} style={styles.title}>
            {data?.name}
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Row gutter={[5, 5]}>
                <Col span={12}>
                  <figure
                    className="small-container-image"
                    onClick={() => setVisiblePreview(true)}
                  >
                    {loading ? (
                      <Skeleton.Image
                        className="skeleton-main-image"
                        active={true}
                      />
                    ) : (
                      <>
                        <img
                          src={
                            path +
                            (mainImage ||
                              data?.imageSmall?.find((x) => x.isAvatar).name)
                          }
                          alt=""
                        />

                        <PreviewCollection
                          imageLarge={data?.imageLarge}
                          mainImage={mainImage}
                          onVisible={setVisiblePreview}
                          isVisible={visiblePreview}
                        />
                      </>
                    )}
                  </figure>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <ProductSubImageList
                data={data?.imageSmall}
                onClick={setMainImage}
              />
            </Col>
            {loading ? (
              <Col span={24}>
                <Skeleton
                  loading={loading}
                  active
                  title
                  paragraph={{ rows: 6 }}
                />
              </Col>
            ) : (
              <Col span={24}>
                <Typography.Paragraph style={styles.key}>
                  <CaretRightOutlined style={styles.bullet} />
                  {t("productDetail.code")}:{" "}
                  <Typography.Paragraph
                    strong
                    ellipsis={{ rows: xs ? 2 : 1 }}
                    style={styles.name}
                  >
                    {data?.code}
                  </Typography.Paragraph>
                </Typography.Paragraph>
                <Typography.Paragraph style={styles.key}>
                  <CaretRightOutlined style={styles.bullet} />
                  {t("productDetail.price")}:{" "}
                  <Typography.Link style={styles.price}>
                    {t("contact")}
                  </Typography.Link>
                </Typography.Paragraph>
                <Typography.Paragraph style={styles.key}>
                  <CaretRightOutlined style={styles.bullet} />
                  {t("productDetail.description")}:{" "}
                  <Typography.Paragraph style={styles.name}>
                    {data?.name}
                  </Typography.Paragraph>
                  <p
                    className="card-description-detail"
                    style={styles.description}
                    dangerouslySetInnerHTML={createMarkup(
                      i18n.language === "en"
                        ? data.descriptionEn
                        : data.descriptionVi || data.descriptionEn
                    )}
                  ></p>
                </Typography.Paragraph>
                {data?.videoUrl && (
                  <div style={styles.video}>
                    <iframe
                      src={data?.videoUrl}
                      // width={600}
                      // height={340}
                      style={styles.iframe}
                      title="PRODUCT"
                    ></iframe>
                  </div>
                )}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    );
  }

  return (
    <Row justify="start" gutter={[0, 40]} style={styles.row}>
      <Col span={24}>
        <Typography.Title level={4} style={styles.title}>
          {data?.name}
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Row gutter={[50, 20]}>
          <Col flex="200px">
            <Row gutter={[5, 5]}>
              <Col span={24}>
                <figure
                  onMouseMove={handleMouseMove}
                  className="small-container-image"
                  onClick={() => setVisiblePreview(true)}
                >
                  {loading ? (
                    <Skeleton.Image
                      className="skeleton-main-image"
                      active={true}
                    />
                  ) : (
                    <>
                      <img
                        src={
                          path +
                          (mainImage ||
                            data?.imageSmall?.find((x) => x.isAvatar).name)
                        }
                        alt=""
                      />
                      <figure
                        style={magnify}
                        className="large-container-image"
                      />
                      <PreviewCollection
                        imageLarge={data?.imageLarge}
                        mainImage={mainImage}
                        onVisible={setVisiblePreview}
                        isVisible={visiblePreview}
                      />
                    </>
                  )}
                </figure>
              </Col>
              <Col span={24}>
                <ProductSubImageList
                  data={data?.imageSmall}
                  onClick={setMainImage}
                />
              </Col>
            </Row>
          </Col>
          {loading ? (
            <Col flex="50%">
              <Skeleton
                loading={loading}
                active
                title
                paragraph={{ rows: 6 }}
              />
            </Col>
          ) : (
            <Col flex="50%">
              <Typography.Paragraph style={styles.key}>
                <CaretRightOutlined style={styles.bullet} />
                {t("productDetail.code")}:{" "}
                <Typography.Text
                  strong
                  ellipsis={{ rows: 1 }}
                  style={styles.name}
                >
                  {data?.code}
                </Typography.Text>
              </Typography.Paragraph>
              <Typography.Paragraph style={styles.key}>
                <CaretRightOutlined style={styles.bullet} />
                {t("productDetail.price")}:{" "}
                <Typography.Link style={styles.price}>
                  {t("contact")}
                </Typography.Link>
              </Typography.Paragraph>
              <Typography.Paragraph style={styles.key}>
                <CaretRightOutlined style={styles.bullet} />
                {t("productDetail.description")}:{" "}
                <Typography.Paragraph style={styles.name}>
                  {data?.name}
                </Typography.Paragraph>
                <p
                  className="card-description-detail"
                  style={styles.description}
                  dangerouslySetInnerHTML={createMarkup(
                    i18n.language === "en"
                      ? data?.descriptionEn
                      : data?.descriptionVi || data?.descriptionEn
                  )}
                ></p>
              </Typography.Paragraph>
              {data?.videoUrl && (
                <div style={styles.video}>
                  <iframe
                    src={data?.videoUrl}
                    width={600}
                    height={340}
                    title="PRODUCT"
                  ></iframe>
                </div>
              )}
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
}
