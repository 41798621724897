import React from "react";
import { Outlet, Navigate } from "react-router-dom";

export default function ProtectedRoute({ children }) {
  console.log({ accessToken: localStorage.getItem("access_token") });
  if (!localStorage.getItem("access_token")) {
    return <Navigate to="/admin/login" />;
  }

  return <Outlet />;
}
