import { Layout, theme } from "antd";
import Header from "../../../components/Admin/Header/index";
import Sidebar from "../../../components/Admin/Sidebar/index";
import AdminContent from "../../../components/Admin/Content/index";
const { Footer } = Layout;

export default function AdminDashBoard() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sidebar />

      <Layout>
        <Header />
        <AdminContent />
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Chrono24us ©2023 Created by Shop Minh Quy
        </Footer>
      </Layout>
    </Layout>
  );
}
