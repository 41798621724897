import { Button, Input, Form, TreeSelect } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  urnFetchCategory,
  urnInsertCategory,
  urnUpdateCategory,
} from "../../api/urn";
import { useFetch } from "../../hook/useFetch";
import { usePost } from "../../hook/usePost";
import { MessageContext } from "../../context/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecursion } from "../../hook/useRecursion";

export default function InsertCateogyForm(props) {
  const [data, setData] = useState({
    id: props?.item?.value || null,
    name: props?.item?.label || null,
    nameEn: props?.item?.nameEn || null,
    parentId: props?.item?.parentId || null,
  });
  const [form] = Form.useForm();
  const [category, setCategory] = useState([]);
  const { response, error } = useFetch(urnFetchCategory);
  const { postResponse, postError, postData } = usePost(
    props ? urnUpdateCategory : urnInsertCategory,
    data
  );
  const { result, onRecursion } = useRecursion(category);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const { openNotification } = useContext(MessageContext);
  const onFinish = (value) => {
    postData();
  };
  const onFinishFailed = (value) => {
    openNotification("Failed inserted", "error");
  };

  useEffect(() => {
    if (response) {
      setCategory(response);
    }
    setLoading(false);
  }, [response]);

  useEffect(() => {
    if (category) {
      onRecursion();
    }
  }, [category]);

  useEffect(() => {
    if (postResponse) {
      openNotification("Successfully inserted", "success");
      navigate("/admin/dashboard/category");
    }
  }, [postResponse, openNotification, navigate]);

  console.log(props);
  useEffect(() => {
    if (props) {
      form.setFieldsValue({
        name: props?.item?.label,
        category: props?.item?.parentId,
        nameEn: props?.item?.nameEn,
      });
    }
  }, [props, form]);

  useEffect(() => {
    if (postError) {
      openNotification("Failed inserted", "error");
    }
  }, [postError, openNotification]);

  const handleChangeNameEn = (e) => {
    setData({ ...data, nameEn: e.target.value });
  };
  const handleChangeNameVi = (e) => {
    setData({ ...data, name: e.target.value });
  };
  const handleSelect = (value) => {
    setData({ ...data, parentId: value });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item label="Category" name="category">
        <TreeSelect
          loading={loading}
          value={data?.parentId}
          dropdownStyle={{
            maxHeight: 400,
            overflow: "auto",
          }}
          placeholder="Please select"
          // allowClear
          treeDefaultExpandAll
          onChange={(value) => handleSelect(value)}
          treeData={result ?? []}
        />
        {/* <Select
          loading={loading}
          placeholder="Select a category"
          name="category"
          value={data?.categoryId}
          onChange={(value) => handleSelect(value)}
          options={options}
        /> */}
      </Form.Item>

      <Form.Item
        label="Name (English)"
        name="nameEn"
        rules={[
          {
            required: true,
            message: "Please input category name!",
          },
        ]}
      >
        <Input
          value={data?.nameEn}
          placeholder="Enter a name"
          onChange={handleChangeNameEn}
        />
      </Form.Item>

      <Form.Item label="Name (Vietnamese)" name="name">
        <Input
          value={data?.name}
          placeholder="Enter a name"
          onChange={handleChangeNameVi}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
