import { Button, Col, Layout, Row, Typography } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import LanguagesSelect from "../../../modules/Select/LanguagesSelect";
import { useTranslation } from "react-i18next";
import ProductSearch from "../../../modules/Search/ProductSearch";
import { useMediaQuery } from "react-responsive";
import ShoppingSidebar from "../Sidebar";
import LogoTop from "../../../assets/logo/logo-chrono24.jpg";
import "./index.css";
import { useNavigate } from "react-router-dom";
const { Header } = Layout;
export default function ShoppingHeader(props) {
  const navigate = useNavigate();
  const xl = useMediaQuery({
    query: "(min-width: 1200px)",
  });
  const lg = useMediaQuery({ query: "(min-width: 992px)" });
  const md = useMediaQuery({ query: "(min-width: 768px)" });
  const sm = useMediaQuery({ query: "(min-width: 576px)" });
  const xs = useMediaQuery({ query: "(max-width: 576px)" });

  const { t } = useTranslation();

  const styles = {
    header: {
      padding: !xl ? "0 10px" : "0 50px",
      background: "#313131",
      height: "64px",
    },
    text: {
      color: "#fff",
      cursor: "pointer",
      fontSize: "14px",
    },
  };
  const backHome = () => {
    navigate("/");
  };
  return (
    <>
      {" "}
      <Header style={styles.header} className="shopping-header">
        <Row justify="space-between" gutter={[10]} wrap={false}>
          <Col>
            <Row
              justify="start"
              gutter={{
                xs: 8,
                sm: 16,
                md: 10,
                lg: 50,
                xl: 50,
              }}
            >
              {!xl && (
                <Col>
                  <Button
                    type="text"
                    icon={<MenuOutlined style={{ color: "white" }} />}
                    onClick={props?.showDrawer}
                  ></Button>
                </Col>
              )}
              {!xl && (
                <div className="logo-top" onClick={() => backHome()}>
                  <img src={LogoTop} alt="" />
                </div>
              )}

              {(xl || lg) && (
                <Col>
                  <Typography.Text style={styles.text}>
                    {t("navbar.home")}
                  </Typography.Text>
                </Col>
              )}
              {(xl || lg) && (
                <Col>
                  <Typography.Text style={styles.text}>
                    {t("navbar.aboutUs")}
                  </Typography.Text>
                </Col>
              )}
              {(xl || lg) && (
                <Col>
                  <Typography.Text style={styles.text}>
                    {t("navbar.howToBuy")}
                  </Typography.Text>
                </Col>
              )}
            </Row>
          </Col>
          <Col>
            <Row align="middle" gutter={[20]} wrap={false}>
              <Col>
                <ProductSearch />
              </Col>
              <Col>
                <LanguagesSelect />
              </Col>
            </Row>
          </Col>

          {/* <Col></Col> */}
        </Row>
      </Header>
      {/* {!xl && <ShoppingSidebar open={open} onClose={onClose} />} */}
    </>
  );
}
