import { Button, Form, Image, Table, Tooltip, Typography } from "antd";
import {
  FileImageOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import {
  urnDeleteProduct,
  urnFetchAllProduct,
  urnFetchProductById,
} from "../../api/urn";
import { useFetchParams } from "../../hook/useFetchParams";
import { useNavigate } from "react-router-dom";
import { path, url } from "../../api/url";
import ImageGallery from "../../assets/icons/image-gallery.png";
import axios from "axios";
import { MessageContext } from "../../context/AuthProvider";
import { useFetch } from "../../hook/useFetch";

export default function ProductTable(props) {
  // props :
  // categoryId => get data
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { openNotification } = useContext(MessageContext);
  const { response, error } = useFetch(urnFetchAllProduct);
  const { fetchParamResponse, fetchParamError, fetchDataParams } =
    useFetchParams(urnFetchProductById(props.categoryId));

  useEffect(() => {
    if (props.categoryId) {
      fetchDataParams();
    }
  }, [props.categoryId]);

  useEffect(() => {
    if (fetchParamResponse) {
      setData(fetchParamResponse);
    }
    setLoading(false);
  }, [fetchParamResponse]);

  useEffect(() => {
    if (response) {
      setData(response);
    }
  }, [response]);

  const handleClickImage = (record) => {
    // navigate("/admin/dashboard/image/insert/" + record.id);
    navigate("/admin/dashboard/image/" + record.id);
  };

  console.log(data);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "5",
      align: "center",
    },
    // {
    //   title: "Image",
    //   dataIndex: "",
    //   align: "center",
    //   render: (record) => {
    //     return <Image width={100} src={path + record.imageName} />;
    //   },
    // },
    {
      title: "Code",
      dataIndex: "code",
      render: (text, record) => {
        return (
          <Typography.Link onClick={() => handleUpdate(record)}>
            {text}
          </Typography.Link>
        );
      },
    },
    {
      title: "...",
      dataIndex: "",
      width: "15%",
      align: "center",
      render: (record) => {
        return (
          <>
            <Tooltip placement="top" title="Upload image" arrow={true}>
              <Button
                type="text"
                shape="round"
                icon={<FileImageOutlined />}
                onClick={() => handleClickImage(record)}
              ></Button>
            </Tooltip>
            <Tooltip placement="top" title="Update" arrow={true}>
              <Button
                type="text"
                shape="round"
                icon={<EditOutlined />}
                onClick={() => handleUpdate(record)}
              ></Button>
            </Tooltip>
            <Tooltip placement="top" title="Delete" arrow={true}>
              <Button
                type="text"
                shape="round"
                danger
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(record)}
              ></Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handleUpdate = (record) => {
    navigate("/admin/dashboard/product/insert", {
      state: record,
    });
  };

  const handleDelete = (record) => {
    axios
      .delete(url + urnDeleteProduct(record?.id))
      .then((response) => {
        if (response.data) {
          openNotification(response.data, "success");
          setData(data.filter((x) => x.id !== record?.id));
        }
      })
      .catch((error) => {
        openNotification(error.message, "error");
      });
  };

  return (
    <Table
      rowKey={(record) => record.id}
      loading={loading}
      bordered
      size="small"
      dataSource={data}
      columns={columns}
      expandable={{
        rowExpandable: (record) => record.children,
      }}
    />
  );
}
