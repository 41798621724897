import { TreeSelect } from "antd";
import React, { useEffect, useState } from "react";
import { useFetch } from "../../hook/useFetch";
import { urnFetchCategory } from "../../api/urn";
import { useRecursion } from "../../hook/useRecursion";

export default function CategoryTreeSelect(props) {
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedValue, setSelectedValue] = useState(null);
  const { response, error } = useFetch(urnFetchCategory);
  const { result, onRecursion } = useRecursion(category);

  const style = {
    minWidth: "200px",
  };

  useEffect(() => {
    if (response) {
      setCategory(response);
    }
    setLoading(false);
  }, [response]);

  useEffect(() => {
    if (category) {
      onRecursion();
    }
  }, [category]);

  const handleSelect = (value) => {
    props.onSelect(value);
    setSelectedValue(value);
  };

  return (
    <TreeSelect
      loading={loading}
      value={selectedValue}
      style={style}
      dropdownStyle={{
        maxHeight: 400,
        overflow: "auto",
      }}
      placeholder="Please select"
      // allowClear
      treeDefaultExpandAll
      onChange={(value) => handleSelect(value)}
      treeData={result ?? []}
    />
  );
}
