import { Carousel, Image, Typography } from "antd";
import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import { useFetchParams } from "../../hook/useFetchParams";
import { urnFetchShoppingProductById } from "../../api/urn";
import { path } from "../../api/url";
import { useMediaQuery } from "react-responsive";
import "./index.css";

export default function ProductCarousel(props) {
  const xs = useMediaQuery({ query: "(max-width: 576px)" });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { fetchParamResponse, fetchParamError, fetchDataParams } =
    useFetchParams(urnFetchShoppingProductById(props?.id));
  const styles = {
    productCarousel: {
      width: !xs && "420px",
    },
    name: {
      color: "rgb(239 215 30)",
      marginBottom: "0px",
      fontSize: xs && "12px",
      paddingRight: xs && "10px",
      //   width: '100%',
    },
  };
  const settings = {
    dots: xs ? false : true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  console.log(data);
  useEffect(() => {
    if (fetchParamResponse) {
      setData(fetchParamResponse);
      setLoading(false);
    }
  }, [fetchParamResponse]);

  useEffect(() => {
    if (fetchParamError) {
      console.log(
        "🚀 ~ file: ProductCarousel.jsx:39 ~ useEffect ~ fetchParamError:",
        fetchParamError
      );
    }
  }, [fetchParamError]);
  useEffect(() => {
    fetchDataParams();
  }, []);

  if (xs) {
    return (
      <div className="products-carousel" style={styles.productCarousel}>
        <Slider className="xs" {...settings}>
          {data?.map((item) => (
            <>
              <Image src={path + item?.imageName} preview={false} />
              <Typography.Paragraph
                strong
                ellipsis={{ rows: 2 }}
                style={styles.name}
              >
                {item.name}
              </Typography.Paragraph>
            </>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <div className="products-carousel" style={styles.productCarousel}>
      <Slider {...settings}>
        {data?.map((item) => (
          <>
            <Image width={200} src={path + item?.imageName} preview={false} />
            <Typography.Paragraph
              strong
              ellipsis={{ rows: 2 }}
              style={styles.name}
            >
              {item.name}
            </Typography.Paragraph>
          </>
        ))}
      </Slider>
      {/* <Typography.Link >Xem thêm</Typography.Link> */}
    </div>
  );
}
