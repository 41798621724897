import React from "react";
import { Col, Row, Space } from "antd";
import Heading from "../../../../modules/Heading/Heading";
import CategoryContent from "../../../../components/Admin/Content/Category/CategoryContent";

export default function AdminCategoryPage() {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Heading title="Category" />
      </Col>
      <Col span={24}>
        <CategoryContent />
      </Col>
    </Row>
  );
}
