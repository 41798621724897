import { Col, Row } from "antd";
import React from "react";
import Heading from "../../../../modules/Heading/Heading";
import { useLocation } from "react-router-dom";
import UpdateCategoryForm from "../../../../modules/Form/UpdateCategoryForm";

export default function UpdateCategoryPage() {
  const { state } = useLocation();
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Heading title="Update new category" />
      </Col>
      <Col span={6}>
        <UpdateCategoryForm item={state} />
      </Col>
    </Row>
  );
}
