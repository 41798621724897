import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { applyMiddleware, createStore } from "redux";
import mySaga from "./redux/sagas";
import reducers from "./redux/reducers";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import App from "./App";
import { notification } from "antd";

// const sagaMiddleware = createSagaMiddleware();

// const store = createStore(reducers, applyMiddleware(sagaMiddleware));

// sagaMiddleware.run(mySaga);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RouterProvider router={router} />
  // <Provider store={store}>
  //   <App/>
  // </Provider>
);
