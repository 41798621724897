import "./index.css";
import React, { useState } from "react";
import { Layout, Menu } from "antd";
import LogoTop from "../../../assets/logo/logo-chrono24.jpg";
import {
  FileImageOutlined,
  FileTextOutlined,
  InboxOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const { Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("Category", "/admin/dashboard/category", <InboxOutlined />),
  getItem("Product", "/admin/dashboard/products", <StarOutlined />),
  // getItem("Image", "/admin/dashboard/image", <FileImageOutlined />),
  getItem("Post", "/admin/dashboard/post", <FileTextOutlined />),
];

export default function AdminSidebar() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const handleClick = (e) => {
    navigate(e.key);
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      className="admin-sidebar"
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="logo-top">
        <img src={LogoTop} alt="" />
      </div>
      <Menu
        theme="dark"
        defaultSelectedKeys={["1"]}
        mode="inline"
        items={items}
        onClick={(e) => handleClick(e)}
      />
    </Sider>
  );
}
