import { Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { path } from '../../api/url';

export default function PreviewCollection(props) {
  const [current, setCurrent] = useState(1);
  const test = (vis) => {
    console.log(vis);
  };
  useEffect(() => {
    if (props.mainImage) {
      let a = props.mainImage.slice(6);
      let cur = props.imageLarge.findIndex((x) => x.name === props.mainImage.replace('small', 'large'));
      setCurrent(cur);
      console.log(cur);
    }
  }, [props.mainImage]);
  return (
    <div
      style={{
        display: 'none',
      }}
    >
      <Image.PreviewGroup
        preview={{
          visible: props.isVisible,
          onVisibleChange: (vis) => props.onVisible(vis),
          current: current,
        }}
      >
        {props.imageLarge?.map((image) => {
          return <Image src={path + image?.name} />;
        })}
      </Image.PreviewGroup>
    </div>
  );
}
