import React, { useEffect, useState } from "react";
import { Button, Col, Row, Space, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import CategoryTable from "../../../../modules/Table/CategoryTable";

export default function CategoryContent() {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleInsertClick = () => {
    navigate("/admin/dashboard/category/insert", { state: null });
  };

  useEffect(() => {
    return () => {
      setSelectedCategory(null);
    };
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row justify="start" gutter={[16]}>
          {/* <Col>
            <Typography.Text>Category: </Typography.Text>
            <CategorySelect onSelect={setSelectedCategory} />
          </Col> */}
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleInsertClick}
            >
              Add Category
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <CategoryTable />
      </Col>
    </Row>
  );
}
