import React from "react";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthProvider";
import { useNavigate, Navigate } from "react-router-dom";
import { url } from "./../../../api/url";
import axios from "axios";

export default function Login() {
  console.log("login");
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);

  const onLogin = () => {
    axios
      .post(url + "/api/auth/login/", {
        username: "minhquy",
        password: "123456",
      })
      .then((response) => {
        localStorage.setItem("access_token", response.data.token);
        setUser(response.data.user);
        navigate("/admin/dashboard");
      })
      .catch((error) => console.log("error: ", error));
  };

  return (
    <>
      <p>Welcome to Note App</p>
      <button onClick={onLogin}>Login</button>
    </>
  );
}
