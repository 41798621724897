import { useEffect, useState } from "react";
import { url } from "../api/url";
import axios from "axios";

export const useFetch = (urn, options) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(url + urn)
        .then((response) => setResponse(response.data))
        .catch((error) => setError(error));
    };
    fetchData();
  }, []);
  return { response, error };
};
