import { Col, Row } from "antd";
import React from "react";
import Heading from "../../../../modules/Heading/Heading";
import ProductContent from "../../../../components/Admin/Content/Product/ProductContent";

export default function AdminProductsPage() {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Heading title="Products" />
      </Col>
      <Col span={24}>
        <ProductContent />
      </Col>
    </Row>
  );
}
