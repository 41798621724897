import { createBrowserRouter, Outlet } from "react-router-dom";
import AuthProvider from "../context/AuthProvider";
import ProtectedRoute from "./ProtectedRoute";
import ShoppingPage from "../pages/Shopping/DashBoard";
import Login from "../pages/Admin/Login";
import ErrorPage from "../pages/ErrorPage";
import DashBoardPage from "../pages/Admin/DashBoard";
import CategoryPage from "../pages/Admin/Content/Category";
import InsertCategoryPage from "../pages/Admin/Content/Category/InsertCategoryPage";
import AdminProductsPage from "../pages/Admin/Content/Product";
import InsertProductPage from "../pages/Admin/Content/Product/InsertProductPage";
import AdminImagePage from "../pages/Admin/Content/Image";
import InsertImagePage from "../pages/Admin/Content/Image/InsertImagePage";
import ProductCategoryList from "../pages/Shopping/Content/Product/ProductCategoryList";
import ProductDetail from "../pages/Shopping/Content/Product/ProductDetail";
import ProductSearchPage from "../pages/Shopping/Content/Search/ProductSearchPage";
// import ProductPage from "../pages/Admin/Content/Product/";
import { I18nextProvider } from "react-i18next";
import i18n from "../locales/i18n";
import UpdateCategoryPage from "../pages/Admin/Content/Category/updateCategoryPage";
import HomePage from "../pages/Shopping/Content/Home/HomePage";
const AuthLayout = () => {
  return (
    <AuthProvider>
      <I18nextProvider i18n={i18n}>
        <Outlet />
      </I18nextProvider>
    </AuthProvider>
  );
};

export default createBrowserRouter([
  {
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    path: "/admin",
    children: [
      {
        element: <Login />,
        path: "/admin/login",
      },
      {
        element: <ProtectedRoute />,
        children: [
          {
            element: <DashBoardPage />,
            path: "/admin/dashboard",
            children: [
              {
                element: <CategoryPage />,
                path: "/admin/dashboard/category",
              },
              {
                element: <InsertCategoryPage />,
                path: "/admin/dashboard/category/insert",
              },
              {
                element: <UpdateCategoryPage />,
                path: "/admin/dashboard/category/update",
              },
              {
                element: <AdminProductsPage />,
                path: "/admin/dashboard/products",
              },
              {
                element: <InsertProductPage />,
                path: "/admin/dashboard/product/insert",
              },
              {
                element: <AdminImagePage />,
                path: "/admin/dashboard/image/:productId",
              },
              {
                element: <InsertImagePage />,
                path: "/admin/dashboard/image/insert/:productId",
              },
              // {
              //   element: <ProductPage />,
              //   path: "/admin/dashboard/product",
              // },
            ],
          },
        ],
      },
    ],
  },
  {
    element: <ShoppingPage />,
    errorElement: <ErrorPage />,
    // path: "/",
    children: [
      {
        element: <HomePage />,
        path: "/",
      },
      {
        element: <ProductSearchPage />,
        path: "/products/search/:searchInput",
      },
      {
        element: <ProductCategoryList />,
        path: "/products/:categoryId",
      },
      {
        element: <ProductDetail />,
        path: "/products/detail/:productId",
      },
    ],
  },
]);
