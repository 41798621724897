import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import ImageTable from "../../../../modules/Table/ImageTable";

export default function ImageContent(props) {
  const navigate = useNavigate();
  const handleInsertClick = () => {
    navigate("/admin/dashboard/image/insert/" + props?.productId);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row justify="start" gutter={[16]}>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleInsertClick}
            >
              Add Image
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>{<ImageTable productId={props?.productId} />}</Col>
    </Row>
  );
}
