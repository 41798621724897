import { Button, Input, Form, TreeSelect } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  urnFetchCategory,
  urnFetchCategoryDetail,
  urnInsertCategory,
} from "../../api/urn";
import { useFetch } from "../../hook/useFetch";
import { usePost } from "../../hook/usePost";
import { MessageContext } from "../../context/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecursion } from "../../hook/useRecursion";
import { useFetchParams } from "../../hook/useFetchParams";

export default function UpdateCategoryForm(props) {
  const [data, setData] = useState({
    name: null,
    parentId: null,
  });
  console.log(props);

  const [category, setCategory] = useState([]);
  const { response, error } = useFetch(urnFetchCategory);
  const [form] = Form.useForm();
  const { fetchParamResponse, fetchParamError, fetchDataParams } =
    useFetchParams(urnFetchCategoryDetail(props.item?.id));
  const { postResponse, postError, postData } = usePost(
    urnInsertCategory,
    data
  );
  const { result, onRecursion } = useRecursion(category);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const { openNotification } = useContext(MessageContext);
  const onFinish = (value) => {
    postData();
  };
  const onFinishFailed = (value) => {
    openNotification("Failed inserted", "error");
  };

  useEffect(() => {
    if (response) {
      setCategory(response);
    }
    if (fetchParamResponse) {
      setData({
        name: fetchParamResponse?.name,
        parentId: fetchParamResponse?.parentId,
      });
    }
    setLoading(false);
  }, [response, fetchParamResponse]);

  useEffect(() => {
    if (category) {
      onRecursion();
    }
  }, [category]);

  useEffect(() => {
    if (postResponse) {
      openNotification("Successfully inserted", "success");
      navigate("/admin/dashboard/category");
    }
  }, [postResponse, openNotification, navigate]);

  useEffect(() => {
    if (props?.item) {
      fetchDataParams();
    }
  }, [props?.item]);

  useEffect(() => {
    if (postError) {
      openNotification("Failed inserted", "error");
    }
  }, [postError, openNotification]);

  const handleChange = (e) => {
    setData({ ...data, name: e.target.value });
  };
  const handleSelect = (value) => {
    setData({ ...data, parentId: value });
  };
  console.log(
    "🚀 ~ file: UpdateCategoryForm.jsx:87 ~ handleSelect ~ data:",
    data
  );

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item label="Category" name="category">
        <TreeSelect
          loading={loading}
          value={data?.parentId}
          dropdownStyle={{
            maxHeight: 400,
            overflow: "auto",
          }}
          placeholder="Please select"
          // allowClear
          treeDefaultExpandAll
          onChange={(value) => handleSelect(value)}
          treeData={result ?? []}
        />
        {/* <Select
              loading={loading}
              placeholder="Select a category"
              name="category"
              value={data?.categoryId}
              onChange={(value) => handleSelect(value)}
              options={options}
            /> */}
      </Form.Item>

      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please input your name!",
          },
        ]}
      >
        <Input
          value={data?.name}
          placeholder="Enter a name"
          onChange={handleChange}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
