import React from "react";
import { Layout } from "antd";
import Breadcrumb from "../../../common/Breadcrumb/Breadcrumb";
import { Outlet } from "react-router-dom";
const { Content } = Layout;

export default function AdminContent() {
  return (
    <Content
      style={{
        margin: "0 16px",
      }}
    >
      {/* <Breadcrumb /> */}
      <Outlet />
    </Content>
  );
}
