import { Col, Row } from "antd";
import React from "react";
import BrandItem from "./BrandItem";

export default function BrandList() {
  const data = [
    { id: 11, name: "Audemars Piguet" },
    { id: 23, name: "Patek Philipphe" },
  ];
  return (
    <Row gutter={[0, 50]}>
      {data?.map((item, index) => {
        return (
          <Col span={24}>
            <BrandItem index={index} item={item} />
          </Col>
        );
      })}
    </Row>
  );
}
