import { Button, Col, Form, Input, Radio, Row, TreeSelect } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { MessageContext } from "../../context/AuthProvider";
import { useRecursion } from "../../hook/useRecursion";
import { useFetch } from "../../hook/useFetch";
import {
  urnFetchCategory,
  urnInsertProduct,
  urnUpdateProduct,
} from "../../api/urn";
import { usePost } from "../../hook/usePost";
import { useNavigate } from "react-router-dom";
import EditorClassic from "../Editor/Editor";

export default function InsertProductForm(props) {
  console.log(
    "🚀 ~ file: InsertProductForm.jsx:17 ~ InsertProductForm ~ props:",
    props
  );

  const initializeData = {
    id: props?.item?.id || null,
    code: props?.item?.code || null,
    name: props?.item?.name || null,
    price: 0,
    descriptionVi: props?.item?.descriptionVi || null,
    descriptionEn: props?.item?.descriptionEn || null,
    videoUrl: props?.item?.videoUrl || null,
    gender: props?.item?.gender ?? null,
    categoryId: props?.item?.categoryId || null,
  };
  const [form] = Form.useForm();
  const [data, setData] = useState(initializeData);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState([]);
  const { response, error } = useFetch(urnFetchCategory);
  const { openNotification } = useContext(MessageContext);
  const { postResponse, postError, postData } = usePost(
    props?.item ? urnUpdateProduct : urnInsertProduct,
    data
  );
  const { result, onRecursion } = useRecursion(category);
  const navigate = useNavigate();
  const onFinish = (value) => {
    postData();
  };

  const onFinishFailed = (value) => {
    openNotification("Failed inserted", "error");
  };

  const onChangeDescription = (value, lang) => {
    if (lang === "en") {
      setData({ ...data, descriptionEn: value });
    }
    if (lang === "vi") {
      setData({ ...data, descriptionVi: value });
    }
  };

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  const getEmbedVideo = (url) => {
    setData({
      ...data,
      videoUrl: `https://www.youtube.com/embed/${getId(url)}`,
    });
    // return `src="//www.youtube.com/embed/${getId(url)}`;
  };

  useEffect(() => {
    if (response) {
      setCategory(response);
    }
    setLoading(false);
  }, [response]);

  useEffect(() => {
    if (category) {
      onRecursion();
    }
  }, [category]);

  useEffect(() => {
    if (postResponse) {
      openNotification("Successfully inserted", "success");
      navigate("/admin/dashboard/products");
    }
  }, [postResponse, openNotification, navigate]);

  useEffect(() => {
    if (postError) {
      openNotification("Failed inserted", "error");
    }
  }, [postError, openNotification]);

  useEffect(() => {
    if (props) {
      form.setFieldsValue({
        code: props?.item?.code,
        name: props?.item?.name,
        descriptionVi: props?.item?.descriptionVi,
        descriptionEn: props?.item?.descriptionEn,
        videoUrl: props?.item?.videoUrl,
        gender: props?.item?.gender,
        categoryId: props?.item?.categoryId,
      });
    }
  }, [props, form]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row gutter={[50, 20]}>
        <Col span={6}>
          <Form.Item
            label="Category"
            name="categoryId"
            rules={[
              {
                required: true,
                message: "Please select a category!",
              },
            ]}
          >
            <TreeSelect
              loading={loading}
              value={data?.categoryId}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
              placeholder="Please select"
              // allowClear
              treeDefaultExpandAll
              onChange={(e) => setData({ ...data, categoryId: e })}
              treeData={result ?? []}
            />
          </Form.Item>

          <Form.Item
            label="Code"
            name="code"
            rules={[
              {
                required: true,
                message: "Please input product code!",
              },
            ]}
          >
            <Input
              value={data?.code}
              placeholder="Enter a product code"
              onChange={(e) => setData({ ...data, code: e.target.value })}
            />
          </Form.Item>

          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input product name!",
              },
            ]}
          >
            <Input
              value={data?.name}
              placeholder="Enter a product name"
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </Form.Item>

          <Form.Item
            name="gender"
            label="Gender"
            rules={[
              {
                required: true,
                message: "Please pick a gender!",
              },
            ]}
          >
            <Radio.Group
              value={data?.gender}
              onChange={(e) => setData({ ...data, gender: e.target.value })}
            >
              <Radio.Button value={0}>Male</Radio.Button>
              <Radio.Button value={1}>Female</Radio.Button>
              <Radio.Button value={2}>Unisex</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="Video" name="videoUrl">
            <Input
              value={data?.videoUrl}
              placeholder="Enter a video address"
              onChange={(e) => getEmbedVideo(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Endglish description" name="descriptionEn">
            <EditorClassic
              data={data?.descriptionEn || ""}
              onChange={(value) => onChangeDescription(value, "en")}
            />
          </Form.Item>
          <Form.Item label="Vietnamese description" name="descriptionVi">
            <EditorClassic
              data={data?.descriptionVi || ""}
              onChange={(value) => onChangeDescription(value, "vi")}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
