import { Col, Row, Typography } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";
import BrandList from "../../../../components/Shopping/Content/Home/BrandList";

export default function HomePage() {
  const xs = useMediaQuery({ query: "(max-width: 576px)" });
  const styles = {
    row: {
      padding: xs ? "0 10px" : "0 50px",
    },
    title: {
      color: "rgb(239 215 30)",
      borderBottom: "1px solid gold",
      margin: "20px 0",
      fontSize: xs && "16px",
    },
  };
  return (
    <>
      {/* <FloatButton.BackTop visibilityHeight={300} /> */}

      <Row justify="start" gutter={[0, 10]} style={styles.row}>
        <Col span={24}>
          <Typography.Title level={4} style={styles.title}>
            Welcome to Minh Quy Shop
          </Typography.Title>
        </Col>

        <Col span={24}>
          <BrandList />
        </Col>
      </Row>
    </>
  );
}
