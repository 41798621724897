import { Button, Col, Popover, Row, Select, Typography } from "antd";
import { GlobalOutlined, CheckOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import "./LanguagesSelect.css";

export default function LanguagesSelect() {
  const xl = useMediaQuery({
    query: "(min-width: 1200px)",
  });
  const lg = useMediaQuery({ query: "(min-width: 992px)" });
  const md = useMediaQuery({ query: "(min-width: 768px)" });
  const sm = useMediaQuery({ query: "(min-width: 576px)" });
  const xs = useMediaQuery({ query: "(max-width: 576px)" });
  const { t, i18n } = useTranslation();
  const handleChange = (value) => {
    i18n.changeLanguage(value);
    localStorage.setItem("language", i18n.language);
  };

  const content = (
    <div className="popover-select-languages">
      <Row gutter={[10]} onClick={() => handleChange("en")}>
        <Col>{t("language.en")}</Col>
        <Col>{i18n.language === "en" && <CheckOutlined />}</Col>
      </Row>
      <Row gutter={[10]} onClick={() => handleChange("vi")}>
        <Col>{t("language.vi")}</Col>
        <Col>{i18n.language === "vi" && <CheckOutlined />}</Col>
      </Row>
    </div>
  );
  const styles = {
    label: {
      color: "#fff",
    },
    icon: {
      color: "#fff",
      fontSize: "16px",
      marginRight: "6px",
    },
  };
  if (xl) {
    return (
      <Row gutter={[10, 10]} wrap={false}>
        <Col>
          <GlobalOutlined style={styles.icon} />

          <Typography.Text style={styles.label}>
            {t("language.label")}
          </Typography.Text>
        </Col>
        <Col>
          <Select
            defaultValue="en"
            style={{
              width: 120,
            }}
            onChange={handleChange}
            options={[
              {
                value: "en",
                label: t("language.en"),
              },
              {
                value: "vi",
                label: t("language.vi"),
              },
            ]}
          />
        </Col>
      </Row>
    );
  } else {
    return (
      <Popover
        content={content}
        title={t("language.label")}
        trigger="click"
        arrow={false}
      >
        <Button icon={<GlobalOutlined />}></Button>
      </Popover>
    );
  }
}
