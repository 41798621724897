export const urnFetchBrand = (categoryId) => "/api/brand?id=" + categoryId;

export const urnFetchProductById = (categoryId) =>
  "/api/product/getProducts/" + categoryId;
export const urnFetchAllProduct = "/api/product/getProducts";
export const urnFetchShoppingProductById = (categoryId) =>
  "/api/product/getShoppingProducts/" + categoryId;
export const urnFetchProductDetail = (productId) =>
  "/api/product/detail/" + productId;
export const urnSearchProduct = (searchInput) =>
  "/api/product/search/" + searchInput;
export const urnInsertProduct = "/api/product/insert";
export const urnUpdateProduct = "/api/product/update";
export const urnDeleteProduct = (productId) =>
  "/api/product/delete/" + productId;

export const urnFetchCategoryDetail = (categoryId) =>
  "/api/category/detail/" + categoryId;
export const urnDeleteCategory = (categoryId) => "/api/category/" + categoryId;
export const urnFetchCategory = "/api/category";
export const urnUpdateCategory = "/api/category/update";
export const urnInsertCategory = "/api/category/insert";

// IMAGE
export const urnAddImages = "/api/image/upload";
export const urnUpdateAvatar = "/api/image/updateAvatar";
export const urnGetImages = (productId) => "/api/image/getImages/" + productId;
export const urnDeleteImages = "/api/image/delete";
