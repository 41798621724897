import { Button, Col, Form, Input, Popover, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./ProductSearch.css";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const { Search } = Input;

export default function ProductSearch() {
  const xl = useMediaQuery({
    query: "(min-width: 1200px)",
  });
  const lg = useMediaQuery({ query: "(min-width: 992px)" });
  const md = useMediaQuery({ query: "(min-width: 768px)" });
  const sm = useMediaQuery({ query: "(min-width: 576px)" });
  const xs = useMediaQuery({ query: "(max-width: 576px)" });
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSearch = (value) => {
    if (value) {
      navigate("/products/search/" + value);
    }
  };
  const content = (
    <div className="popover-search-input">
      <Row gutter={[10]}>
        <Col>
          <Search
            allowClear
            placeholder={t("search.placeholder")}
            onSearch={onSearch}
            className="search-input"
            style={{ width: "300px" }}
          />
        </Col>
      </Row>
    </div>
  );

  if (xl || lg || md) {
    return (
      <Form autoComplete="off" className="search-product">
        <Form.Item label={t("search.label")} className="search-product-item">
          <Search
            allowClear
            placeholder={t("search.placeholder")}
            onSearch={onSearch}
            className="search-input"
          />
        </Form.Item>
      </Form>
    );
  }
  if (sm || xs) {
    return (
      <Popover
        content={content}
        title={t("search.label")}
        trigger="click"
        arrow={false}
      >
        <Button icon={<SearchOutlined />}></Button>
      </Popover>
    );
  }
}
