import { Image } from "antd";
import React from "react";
import AudemarsImage from "../../assets/image/Audemars-Piguet-Logo.png";
import PatekImage from "../../assets/image/Patek-Philipphe-Logo.png";
import { useMediaQuery } from "react-responsive";

export default function BrandImage(props) {
  const xs = useMediaQuery({ query: "(max-width: 576px)" });
  const styles = {
    brandImage: {
      background: "lightgoldenrodyellow",
    },
  };
  if (xs) {
    return (
      <div className="brandImage" style={styles.brandImage}>
        <Image
          height={80}
          src={props?.index % 2 === 0 ? PatekImage : AudemarsImage}
          preview={false}
        />
      </div>
    );
  }
  return (
    <div className="brandImage" style={styles.brandImage}>
      <Image
        width={420}
        height={200}
        src={props?.index % 2 === 0 ? PatekImage : AudemarsImage}
        preview={false}
      />
    </div>
  );
}
