import {
  Col,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Table,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  urnDeleteCategory,
  urnFetchBrand,
  urnFetchCategory,
} from "../../api/urn";
import { useFetch } from "../../hook/useFetch";
import { useRecursion } from "../../hook/useRecursion";
import { useNavigate } from "react-router-dom";
import { useDelete } from "../../hook/useDelete";
import axios from "axios";
import { url } from "../../api/url";
import { MessageContext } from "../../context/AuthProvider";

export default function CategoryTable(props) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const { openNotification } = useContext(MessageContext);
  const [loading, setLoading] = useState(true);
  const { response, error } = useFetch(urnFetchCategory);
  const { result, onRecursion } = useRecursion(data, "table");

  useEffect(() => {
    if (response) {
      console.log(
        "🚀 ~ file: CategoryTable.jsx:18 ~ useEffect ~ response:",
        response
      );

      setData(response);
    }
    setLoading(false);
  }, [response]);

  useEffect(() => {
    if (data) {
      onRecursion("table");
    }
  }, [data]);

  const columns = [
    {
      title: "ID",
      dataIndex: "value",
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "label", // nhớ đổi lại là nameEn
      editable: true,
    },
    {
      title: "...",
      dataIndex: "",
      width: "15%",
      render: (_, record) => (
        <Row gutter={[15]}>
          <Col>
            <Typography.Link onClick={() => handleUpdate(record)}>
              Edit
            </Typography.Link>
          </Col>
          <Col>
            <Typography.Link
              style={{ color: "red" }}
              onClick={() => handleDelete(record.value)}
            >
              Delete
            </Typography.Link>
          </Col>
        </Row>
      ),
    },
  ];

  const handleUpdate = (record) => {
    navigate("/admin/dashboard/category/insert", {
      state: record,
    });
  };
  const handleDelete = (value) => {
    axios
      .delete(url + urnDeleteCategory(value))
      .then((response) => {
        if (response.data) {
          openNotification(response.data, "success");
          setData(data.filter((x) => x.id !== value));
        }
      })
      .catch((error) => {
        openNotification(error.message, "error");
      });
  };
  // const handleSave = (row) => {
  //   const newData = [...result];
  //   const index = newData.findIndex((item) => row.key === item.key);
  //   const item = newData[index];
  //   newData.splice(index, 1, {
  //     ...item,
  //     ...row,
  //   });
  //   setDataSource(newData);
  // };
  // const components = {
  //   body: {
  //     row: EditableRow,
  //     cell: EditableCell,
  //   },
  // };
  // const columns = defaultColumns.map((col) => {
  //   if (!col.editable) {
  //     return col;
  //   }
  //   return {
  //     ...col,
  //     onCell: (record) => ({
  //       record,
  //       editable: col.editable,
  //       dataIndex: col.dataIndex,
  //       title: col.title,
  //       handleSave,
  //     }),
  //   };
  // });
  console.log(result);
  return (
    <Form form={form} component={false}>
      <Table
        rowKey={(record) => record.value}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        loading={loading}
        bordered
        size="small"
        scroll={{ y: "60vh" }}
        dataSource={result}
        columns={columns}
        expandable={{
          rowExpandable: (record) => record.children,
        }}
      />
    </Form>
  );
}
