import { Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  urnFetchShoppingProductById,
  urnSearchProduct,
} from "../../../../api/urn";
import { useFetchParams } from "../../../../hook/useFetchParams";
import ProductCategoryItem from "../Product/ProductCategoryItem";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

export default function ProductSearchPage() {
  const params = useParams();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { fetchParamResponse, fetchParamError, fetchDataParams } =
    useFetchParams(urnSearchProduct(params.searchInput));

  const xl = useMediaQuery({
    query: "(min-width: 1200px)",
  });
  const lg = useMediaQuery({ query: "(min-width: 992px)" });
  const md = useMediaQuery({ query: "(min-width: 768px)" });
  const sm = useMediaQuery({ query: "(min-width: 576px)" });
  const xs = useMediaQuery({ query: "(max-width: 576px)" });
  useEffect(() => {
    if (params?.searchInput) {
      fetchDataParams();
    }
  }, [params]);

  useEffect(() => {
    if (fetchParamResponse) {
      setData(fetchParamResponse);
      setLoading(false);
    }
  }, [fetchParamResponse]);

  const styles = {
    row: {
      padding: xs ? "0 10px" : "0 50px",
    },
    title: {
      color: "rgb(239 215 30)",
      borderBottom: "1px solid gold",
      margin: "20px 0",
    },
    search: {
      marginTop: "20px",
      // marginBottom: '0 !important',
      // color: 'white !important',
    },
  };

  if (xs) {
    return (
      <>
        <Row justify="start" gutter={[0, 10]} style={styles.row}>
          <Col span={24}>
            <Typography.Title level={4} style={styles.title}>
              {t("search.keywords")}: "{params?.searchInput}"
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Typography.Text style={{ color: "#fff", fontSize: "15px" }}>
              {t("search.result")}: {data?.length || 0}{" "}
              {data?.length > 1 ? t("search.products") : t("search.product")}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              {data?.map((item) => {
                return (
                  <Col span={12}>
                    <ProductCategoryItem item={item} />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Row justify="start" gutter={[0, 10]} style={styles.row}>
        <Col span={24}>
          <Typography.Title level={4} style={styles.title}>
            {t("search.keywords")}: "{params?.searchInput}"
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Text style={{ color: "#fff", fontSize: "15px" }}>
            {t("search.result")}: {data?.length || 0}{" "}
            {data?.length > 1 ? t("search.products") : t("search.product")}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Row gutter={[0, 30]}>
            {data?.map((item) => {
              return (
                <Col span={24}>
                  <ProductCategoryItem item={item} />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </>
  );
}
